.header {
  padding-top: var(--mantine-spacing-sm);
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-2), transparent);
  margin-bottom: rem(120px);
}

.mainSection {
  padding-bottom: var(--mantine-spacing-sm);
}

.user {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  padding: 2px;
  border-radius: var(--mantine-radius-sm);
  transition: background-color 100ms ease;

  &:hover {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-8));
  }

  @media (max-width: $mantine-breakpoint-xs) {
    display: none;
  }
}

.userActive {
  background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-8));
}

.tabsList {
  &::before {
    display: none;
  }
}

.tab {
  font-weight: 500;
  height: rem(38);
  background-color: transparent;
  position: relative;
  border: 0;
  /* bottom: -1px; */

  &::before,
  &::after {
    content: none;
    /* background-color: light-dark(
      var(--mantine-color-gray-2),
      var(--mantine-color-dark-7)
    ) !important; */
  }

  &:hover {
    background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
  }

  &[data-active=true] {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));
    border-color: light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-7));
    border-bottom-color: transparent;
  }
}