h1 {
  font-size: 2em;
  text-align: center;
  font-weight: 800;
  margin-bottom: 50px;
}

h2 {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 1.6em;
  font-weight: 600;
}
h3 {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 1.3em;
  font-weight: 500;
}
h4 {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 1.1em;
  font-weight: 400;
}
body {
  font-weight: 400;
}
.content {
  background-color: #f0f0f0;
  padding: 5px 12px;
}
